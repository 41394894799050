import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import apiCalls from "./apiCalls";

Vue.use(Vuex);

const store: StoreOptions<[]> = {
  modules: {
    apiCalls,
  },
};

export default new Vuex.Store(store);
