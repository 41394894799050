import RequestState from "@/models/api/RequestState";
import StringMap from "@/models/StringMap";

interface RequestResponse {
  result: string;
  data: any;
  headers: any;
}

interface ParsedResponse {
  errors: { [key: string]: string | number }[];
  data: any;
  headers?: any;
}

const parseResponse = (
  res: RequestResponse | RequestResponse[]
): ParsedResponse => {
  const errors: { [key: string]: string | number }[] = [];
  const fetchedData: StringMap[] = [];
  const resHeaders: StringMap[] = [];

  if (Array.isArray(res)) {
    res.map(({ result, data, headers }, index) => {
      if (result === RequestState.ERROR) {
        errors.push({ index, ...data });
        return;
      }

      if (result === RequestState.SUCCESS) {
        data ? fetchedData.push(data) : fetchedData.push({});
        headers ? resHeaders.push(headers) : resHeaders.push({});
      }
    });

    return { errors, data: fetchedData, headers: resHeaders };
  }

  return res.result === RequestState.ERROR
    ? { errors: [res.data], data: {} }
    : { errors: [], data: res.data || {}, headers: res.headers || {} };
};

export default parseResponse;
