import axios from "axios";
import store from "../store";
import { Modules, Mutations } from "@/models/store";
import RequestState from "@/models/api/RequestState";
import ApiRequestId from "@/models/api/ApiRequestId";
import ApiRequest from "@/models/api/ApiRequest";

interface RequestResponse {
  result: string;
  data: never | { [key: string]: string };
  headers: any;
}

const setRequestState = (
  action: string,
  actionState: string,
  id: ApiRequestId
) =>
  store.commit(`${Modules.API_CALLS}/${Mutations.SET_API_CALL_STATE}`, {
    action,
    actionState,
    id,
  });

const createRequest = ({
  action,
  id = null,
  url,
  headers,
  withAutorization = false,
  ...requestSettings
}: ApiRequest): any => {
  action ? setRequestState(action, RequestState.FETCHING, id) : null;

  return axios({
    ...requestSettings,
    url,
    headers: {
      ...(withAutorization && { Authorization: `Bearer {TODO TOKEN GETTER}` }),
      ...headers,
    },
  })
    .then(({ data, headers }: never): RequestResponse => {
      action ? setRequestState(action, RequestState.SUCCESS, id) : null;
      return { result: RequestState.SUCCESS, data, headers };
    })
    .catch((err: string): RequestResponse => {
      action ? setRequestState(action, RequestState.ERROR, id) : null;
      console.error(err);
      return {
        result: RequestState.ERROR,
        data: { message: err, url },
        headers: {},
      };
    });
};

export default createRequest;
