import Vue, { VNode } from "vue";
import {
  VMain,
  VContainer,
  VCard,
  VCardTitle,
  VCardText,
  VForm,
  VBtn,
  VTextarea,
  VSelect,
  VSlider,
} from "vuetify/lib";
import LANGUAGES from "@/constants/languages";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import vocallsLogin from "@vocalls/vocalls-login";

const Content = Vue.extend({
  props: {},
  data: () => ({
    formData: {
      text: "",
      voice: "",
      language: "cs",
      tempo: 1,
      pitch: 1,
      format: "wav",
    },
    isFormValid: false,
    isAudioFetching: false,
    token: "",
  }),
  computed: {
    languageVoices(): any[] {
      if (!this.formData.language) {
        return [];
      }

      const selectedLanguage = LANGUAGES.find(
        ({ id }) => id === this.formData.language
      );
      const allVoices = selectedLanguage?.ttsSettings.map(
        ({ engine, voices }) =>
          voices.map(({ tts }) => ({ text: `${tts} (${engine})`, value: tts }))
      );
      return allVoices?.reduce((a, b) => [...a, ...b], []) || [];
    },
  },
  methods: {
    getVoiceConfig() {
      const selectedLanguage = LANGUAGES.find(
        ({ id }) => id === this.formData.language
      );

      const voiceConfig = selectedLanguage?.ttsSettings.map(
        ({ engine, voices }) => {
          const voice = voices.find(({ tts }) => tts === this.formData.voice);

          if (voice) {
            return { engine, code: voice.code, tts: voice.tts };
          }
        }
      );

      return voiceConfig?.filter((n) => n)[0];
    },

    simpleHash(str: string) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
      }
      return new Uint32Array([hash])[0].toString(36);
    },

    async generateAudio(): Promise<void> {
      const { language, voice, ...reqData } = this.formData;
      const voiceConfig = this.getVoiceConfig();

      const ref: any = this.$refs;
      const isFormValid = ref.form.validate();

      if (!isFormValid) {
        return;
      }

      this.isAudioFetching = true;

      const audioResponse = await createRequest({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/Administration/Tools/TtsGenerator`,
        data: { voice: voiceConfig, ...reqData },
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      const { data, errors } = parseResponse(audioResponse);

      if (data) {
        this.isAudioFetching = false;
      }

      if (errors.length > 0) {
        return;
      }

      const fileName = `tts_lng-${voiceConfig?.code}_hash-${this.simpleHash(
        this.formData.text
      )}_voc-${voiceConfig?.tts}.${this.formData.format}`;

      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    },
  },

  created() {
    vocallsLogin({
      onSuccess: (token: string) => (this.token = token),
      loginUrl: `${process.env.VUE_APP_WEBAPP_URL}/Account/Login`,
      getTokenUrl: `${process.env.VUE_APP_WEBAPP_URL}/Account/Token`,
    });
  },

  render(): VNode {
    const { formData } = this;

    return (
      <VMain class="tertiary">
        <VContainer>
          <VCard
            maxWidth="800"
            class="mx-auto my-5 text-left"
            disabled={this.isAudioFetching}
            loading={this.isAudioFetching}
          >
            <VCardTitle class="text-center justify-center py-6">
              <h2 class="text-h5 font-weight-bold primary--text">
                TTS Generator
              </h2>
            </VCardTitle>
            <VCardText>
              <VForm ref="form" lazyValidation vModel={this.isFormValid}>
                <VTextarea
                  vModel={formData.text}
                  label="Enter text"
                  outlined
                  rules={[(val: string) => !!val || "Required field"]}
                />
                <div class="d-flex align-center">
                  <VSelect
                    vModel={formData.language}
                    items={LANGUAGES.map(({ id, name }) => ({
                      text: name,
                      value: id,
                    }))}
                    label="Language"
                    outlined
                    class="mr-5"
                    style="max-width: 50%;"
                  />
                  <VSlider
                    vModel={formData.tempo}
                    label="Tempo"
                    thumbLabel="always"
                    min="-2"
                    max="2"
                    step="0.1"
                  />
                </div>
                <div class="d-flex align-center">
                  <VSelect
                    vModel={formData.voice}
                    items={this.languageVoices}
                    rules={[(val: string) => !!val || "Required field"]}
                    label="Voice"
                    outlined
                    class="mr-5"
                    style="max-width: 50%;"
                  />
                  <VSlider
                    vModel={formData.pitch}
                    label="Pitch"
                    thumbLabel="always"
                    min="-2"
                    max="2"
                    step="0.1"
                  />
                </div>
                <VSelect
                  vModel={formData.format}
                  items={["alaw", "mulaw", "mp3", "wav"]}
                  label="Format"
                  outlined
                  style="max-width: 50%;"
                />
              </VForm>
            </VCardText>
            <VBtn
              elevation="0"
              color="primary rounded-t-0"
              large
              block
              onClick={this.generateAudio}
            >
              Generate
            </VBtn>
          </VCard>
        </VContainer>
      </VMain>
    );
  },
});

export default Content;
