const LANGUAGES = [
  {
    id: "cs",
    name: "Czech",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "cs-CZ",
            tts: "cs-CZ-Wavenet-A",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "cs-CZ",
            tts: "cs-CZ-VlastaNeural",
          },
          {
            code: "cs-CZ",
            tts: "cs-CZ-AntoninNeural",
          },
        ],
      },
    ],
  },
  {
    id: "de",
    name: "German",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-A",
          },
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-B",
          },
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-C",
          },
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-D",
          },
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-E",
          },
          {
            code: "de-DE",
            tts: "de-DE-Wavenet-F",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "de-AT",
            tts: "de-AT-IngridNeural",
          },
          {
            code: "de-AT",
            tts: "de-AT-JonasNeural",
          },
          {
            code: "de-DE",
            tts: "de-DE-KatjaNeural",
          },
          {
            code: "de-DE",
            tts: "de-DE-ConradNeural",
          },
          {
            code: "de-CH",
            tts: "de-CH-LeniNeural",
          },
          {
            code: "de-CH",
            tts: "de-CH-JanNeural",
          },
        ],
      },
    ],
  },
  {
    id: "en",
    name: "English",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "en-US",
            tts: "en-US-Wavenet-A",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-B",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-C",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-D",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-E",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-F",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-G",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-H",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-I",
          },
          {
            code: "en-US",
            tts: "en-US-Wavenet-J",
          },
          {
            code: "en-GB",
            tts: "en-GB-Wavenet-A",
          },
          {
            code: "en-GB",
            tts: "en-GB-Wavenet-B",
          },
          {
            code: "en-GB",
            tts: "en-GB-Wavenet-C",
          },
          {
            code: "en-GB",
            tts: "en-GB-Wavenet-D",
          },
          {
            code: "en-GB",
            tts: "en-GB-Wavenet-F",
          },
          {
            code: "en-AU",
            tts: "en-AU-Wavenet-A",
          },
          {
            code: "en-AU",
            tts: "en-AU-Wavenet-B",
          },
          {
            code: "en-AU",
            tts: "en-AU-Wavenet-C",
          },
          {
            code: "en-AU",
            tts: "en-AU-Wavenet-D",
          },
          {
            code: "en-IN",
            tts: "en-IN-Wavenet-A",
          },
          {
            code: "en-IN",
            tts: "en-IN-Wavenet-B",
          },
          {
            code: "en-IN",
            tts: "en-IN-Wavenet-C",
          },
          {
            code: "en-IN",
            tts: "en-IN-Wavenet-D",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "en-AU",
            tts: "en-AU-NatashaNeural",
          },
          {
            code: "en-AU",
            tts: "en-AU-WilliamNeural",
          },
          {
            code: "en-CA",
            tts: "en-CA-ClaraNeural",
          },
          {
            code: "en-IN",
            tts: "en-IN-NeerjaNeural",
          },
          {
            code: "en-IN",
            tts: "en-IN-PrabhatNeural",
          },
          {
            code: "en-IE",
            tts: "en-IE-EmilyNeural",
          },
          {
            code: "en-IE",
            tts: "en-IE-ConnorNeural",
          },
          {
            code: "en-PH",
            tts: "en-PH-RosaNeural",
          },
          {
            code: "en-PH",
            tts: "en-PH-JamesNeural",
          },
          {
            code: "en-GB",
            tts: "en-GB-LibbyNeural",
          },
          {
            code: "en-GB",
            tts: "en-GB-MiaNeural",
          },
          {
            code: "en-GB",
            tts: "en-GB-RyanNeural",
          },
          {
            code: "en-US",
            tts: "en-US-AriaNeural",
          },
          {
            code: "en-US",
            tts: "en-US-JennyNeural",
          },
          {
            code: "en-US",
            tts: "en-US-GuyNeural",
          },
        ],
      },
    ],
  },
  {
    id: "es",
    name: "Spanish",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "es-ES",
            tts: "es-ES-Wavenet-B",
          },
          {
            code: "es-ES",
            tts: "es-ES-Wavenet-C",
          },
          {
            code: "es-ES",
            tts: "es-ES-Wavenet-D",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "es-ES",
            tts: "es-ES-ElviraNeural",
          },
          {
            code: "es-ES",
            tts: "es-ES-AlvaroNeural",
          },
        ],
      },
    ],
  },
  {
    id: "fr",
    name: "French",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "fr-CA",
            tts: "fr-CA-Wavenet-A",
          },
          {
            code: "fr-CA",
            tts: "fr-CA-Wavenet-B",
          },
          {
            code: "fr-CA",
            tts: "fr-CA-Wavenet-C",
          },
          {
            code: "fr-CA",
            tts: "fr-CA-Wavenet-D",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-Wavenet-A",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-Wavenet-B",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-Wavenet-C",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-Wavenet-D",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-Wavenet-E",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "fr-CA",
            tts: "fr-CA-SylvieNeural",
          },
          {
            code: "fr-CA",
            tts: "fr-CA-AntoineNeural",
          },
          {
            code: "fr-CA",
            tts: "fr-CA-JeanNeural",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-DeniseNeural",
          },
          {
            code: "fr-FR",
            tts: "fr-FR-HenriNeural",
          },
          {
            code: "fr-CH",
            tts: "fr-CH-ArianeNeural",
          },
          {
            code: "fr-CH",
            tts: "fr-CH-FabriceNeural",
          },
        ],
      },
    ],
  },
  {
    id: "it",
    name: "Italian",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "it-IT",
            tts: "it-IT-Wavenet-A",
          },
          {
            code: "it-IT",
            tts: "it-IT-Wavenet-B",
          },
          {
            code: "it-IT",
            tts: "it-IT-Wavenet-C",
          },
          {
            code: "it-IT",
            tts: "it-IT-Wavenet-D",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "it-IT",
            tts: "it-IT-Cosimo",
          },
          {
            code: "it-IT",
            tts: "it-IT-LuciaRUS",
          },
        ],
      },
    ],
  },
  {
    id: "nl",
    name: "Dutch",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "nl-NL",
            tts: "nl-NL-Wavenet-A",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-Wavenet-B",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-Wavenet-C",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-Wavenet-D",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-Wavenet-E",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "nl-BE",
            tts: "nl-BE-DenaNeural",
          },
          {
            code: "nl-BE",
            tts: "nl-BE-ArnaudNeural",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-ColetteNeural",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-FennaNeural",
          },
          {
            code: "nl-NL",
            tts: "nl-NL-MaartenNeural",
          },
        ],
      },
    ],
  },
  {
    id: "pl",
    name: "Polish",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "pl-PL",
            tts: "pl-PL-Wavenet-A",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-Wavenet-B",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-Wavenet-C",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-Wavenet-D",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-Wavenet-E",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "pl-PL",
            tts: "pl-PL-AgnieszkaNeural",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-ZofiaNeural",
          },
          {
            code: "pl-PL",
            tts: "pl-PL-MarekNeural",
          },
        ],
      },
    ],
  },
  {
    id: "ru",
    name: "Russian",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "ru-RU",
            tts: "ru-RU-Wavenet-A",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-Wavenet-B",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-Wavenet-C",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-Wavenet-D",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-Wavenet-E",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "ru-RU",
            tts: "ru-RU-DariyaNeural",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-SvetlanaNeural",
          },
          {
            code: "ru-RU",
            tts: "ru-RU-DmitryNeural",
          },
        ],
      },
    ],
  },
  {
    id: "sk",
    name: "Slovak",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "sk-SK",
            tts: "sk-SK-Wavenet-A",
          },
        ],
      },
      {
        engine: "Microsoft",
        voices: [
          {
            code: "sk-SK",
            tts: "sk-SK-ViktoriaNeural",
          },
          {
            code: "sk-SK",
            tts: "sk-SK-LukasNeural",
          },
        ],
      },
    ],
  },
  {
    id: "sr",
    name: "Serbian",
    ttsSettings: [
      {
        engine: "Google",
        voices: [
          {
            code: "sr-RS",
            tts: "sr-rs-Standard-A",
          },
        ],
      },
    ],
  },
];

export default LANGUAGES;
