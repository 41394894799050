import Vue, { VNode } from "vue";
import { VAppBar, VImg } from "vuetify/lib";
import imgUrl from "@/assets/img/logo.svg";

const Header = Vue.extend({
  props: {},
  data: () => ({}),
  methods: {},

  render(): VNode {
    return (
      <VAppBar app color="primary">
        <a href="./">
          <VImg src={imgUrl} max-width="125" max-height="28"></VImg>
        </a>
      </VAppBar>
    );
  },
});

export default Header;
