import Vue, { VNode } from "vue";
import { VFooter, VCol } from "vuetify/lib";

const Footer = Vue.extend({
  props: {},
  data: () => ({}),
  methods: {},

  render(): VNode {
    return (
      <VFooter class="secondary primary--text" padlles>
        <VCol class="text-center" cols="12">
          Vocalls - Copyright {new Date().getFullYear()}, All rights reserved.
        </VCol>
      </VFooter>
    );
  },
});

export default Footer;
