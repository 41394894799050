import Vue, { VNode } from "vue";
import { VApp } from "vuetify/lib";

import Header from "@/components/Header";
import Content from "@/components/Content";
import Footer from "@/components/Footer";

const Root = Vue.extend({
  props: {},
  data: () => ({}),
  methods: {},

  render(): VNode {
    return (
      <VApp class="tertiary">
        <Header />
        <Content />
        <Footer />
      </VApp>
    );
  },
});

export default Root;
