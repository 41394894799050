import { GetterTree } from "vuex";
import getApiCallState from "@/helpers/getApiCallState";
import { Getters } from "@/models/store";
import ApiCallsState from "@/models/store/ApiCallsState";
import ApiRequestId from "@/models/api/ApiRequestId";
import RequestState from "@/models/api/RequestState";

const getters: GetterTree<ApiCallsState, []> = {
  [Getters.IS_ACTION_FETCHING]: (state) => (action: string, id: ApiRequestId) =>
    getApiCallState({
      state,
      action,
      actionState: RequestState.FETCHING,
      id,
    }),

  [Getters.IS_ACTION_SUCCESS]: (state) => (action: string, id: ApiRequestId) =>
    getApiCallState({ state, action, actionState: RequestState.SUCCESS, id }),

  [Getters.IS_ACTION_ERROR]: (state) => (action: string, id: ApiRequestId) =>
    getApiCallState({ state, action, actionState: RequestState.ERROR, id }),

  [Getters.IS_ACTION_INITIAL]: (state) => (action: string, id: ApiRequestId) =>
    getApiCallState({ state, action, actionState: RequestState.INITIAL, id }),
};

export default getters;
